import styled, { css } from 'styled-components';


const TimelineIcon = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    `;

const TimelineIconWrapper = styled.div`
background-color: ${props => props.main ? "#bb86fc" : "#a3a3a3"};
margin-right: 10px;    
padding: 15px;
border-radius: 50px;
width: 30px;
height: 30px;
`;

const TimelineTitleText = styled.p`
    text-transform: uppercase;
`;

const TimelineTitleRow = styled.div`
    width:20%;
    display:flex;
    flex-direction: row;
`;

export default function TimelineTitle({title, icon, main}) {

    return (
        <TimelineTitleRow>
            <TimelineIconWrapper main={main}>
                <TimelineIcon src={icon}/>
            </TimelineIconWrapper>
            <TimelineTitleText>{title}</TimelineTitleText>            
        </TimelineTitleRow>
    );
};