import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import WelcomeScreen from './Routes/WelcomeScreen';
import LocationsScreen from './Routes/LocationsScreen';
import InvestigateScreen from './Routes/InvestigateScreen';
import NeedHelpScreen from './Routes/NeedHelpScreen';
import Timeline from './Components/Timeline';
import AssetBrowser from './Components/AssetBrowser';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<WelcomeScreen />} />
        <Route path='/locations' element={<LocationsScreen />} />
        <Route path="/locations/:locationId" element={<InvestigateScreen />} />
        <Route path="/locations/:locationId/:objectId/timeline" element={<Timeline />} />
        <Route path="/locations/:locationId/:objectId/timeline/:type/:year/:month/" element={<AssetBrowser />} />
        <Route path='/help/:contentTitle' element={<NeedHelpScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
