import { Panel, PanelsRow } from "./Panel";
import { getData, getLocationData } from "../Misc/data";
import WindowPanel from "./WindowPanel";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { Description, DescriptionContainer } from "../Routes/LocationsScreen";
import styled from "styled-components";
import { InterpolateSmooth } from "three";
import { canvas } from "leaflet";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { ZoomControlsWrapper, ZoomButton, ZoomButtonDash, ButtonImageWrapper } from './ModelPreview';

const Image = styled.img`
    width: 100%;
    height: 100%;
    max-height: 50vh;
    object-fit: contain;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    /* height: 75vh; */
    width:100%;
    height:100%;
`

const PhotoDescription = styled(Description)`
text-align: start;
`

const ImageContainer = styled.div`
    /* position:relative; */
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
`;

const ColumnCanvas = styled.canvas.attrs(props => ({
    style: {
        width: props.width + "px",
        height: props.height + "px",
        left: props.left,
    }
}))`
position: absolute;
/* top: 0; */
`;

export default function AssetBrowser() {

    const imgRef = useRef();
    const canvasRef = useRef();
    const transformRef = useRef();
    const params = useParams();
    const [selectedObject, setSelectedObject] = useState(null);
    const [currentDataRange, setCurrentDataRange] = useState(null);
    const [selectedObjectIndex, setSelectedObjectIndex] = useState(0);
    const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });
    const [locationData, setLocationData] = useState(null);

    useEffect(() => {
        getLocationData().then(locationData => {
            setLocationData(locationData);
        });
        getData(params.locationId).then(data => {
            console.log(data);
            //let obj = findById(data, params.dataId);
            let photos = data.find(x => x.year == params.year && x.month == params.month && x.type == params.type).items;
            //find photos that has any region with the same columnId as objectId in params
            let photosWithObject = photos.filter(x => x.regions.find(y => y.columnId == params.objectId));
            photosWithObject = photosWithObject.map(x => {
                x.regions = x.regions.filter(y => y.columnId == params.objectId);
                return x;
            });

            console.log(photosWithObject);

            setCurrentDataRange(photosWithObject);
            setSelectedObject(photosWithObject[selectedObjectIndex]);
            console.log(photos);
        });
    }, [params]);

    useEffect(() => {
        if (canvasRef.current && selectedObject) {
            const ctx = canvasRef.current.getContext("2d");
            let dimensions = getContainedSize(imgRef.current);
            console.log(dimensions);
            console.log(`Natural width ${imgRef.current.naturalWidth} height ${imgRef.current.naturalHeight}, ratio: ${imgRef.current.naturalWidth / imgRef.current.naturalHeight}`);
            console.log(`Current width ${imgRef.current.width} height ${imgRef.current.height}, ratio: ${imgRef.current.width / imgRef.current.height}`);

            ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            ctx.fillStyle = '#000000AA';

            let originX = Math.floor(imgRef.current.width / 2 - (dimensions[0] / 2));
            let originY = 0;

            ctx.globalCompositeOperation = 'xor';
            ctx.fillRect(Math.floor(imgRef.current.width / 2 - (dimensions[0] / 2)), 0, dimensions[0], dimensions[1]);
            ctx.globalCompositeOperation = 'destination-out';

            for (var region of selectedObject.regions) {
                //ctx.beginPath();
                //ctx.strokeStyle = '#ff0000';
                let path = new Path2D();
                let lastPoint = null;
                let allPoints = [];
                for (let i = 0; i < region.points.length; i++) {
                    let point = region.points[i % region.points.length];
                    let translatedX = point.x / imgRef.current.naturalWidth;
                    let translatedY = point.y / imgRef.current.naturalHeight;
                    let x = originX + translatedX * dimensions[0];
                    let y = originY + translatedY * dimensions[1];
                    if (lastPoint)
                        path.lineTo(x, y);
                    if (i == 0)
                        path.moveTo(x, y);

                    allPoints.push({ x: x, y: y });
                    lastPoint = { x, y };
                }
                path.closePath();
                //ctx.fillStyle = '#FFFFFFFF';
                let mean = allPoints.reduce((a, b) => ({ x: a.x + b.x, y: a.y + b.y }));
                mean.x /= allPoints.length;
                mean.y /= allPoints.length;
                let diffMax = allPoints.reduce((a, b) => ({ x: Math.max(Math.abs(a.x - mean.x), Math.abs(b.x - mean.x)), y: Math.max(Math.abs(a.y - mean.y), Math.abs(b.y - mean.y)) }));
                let circleRadius = diffMax.y;
                console.log(diffMax);
                console.log(mean);
                let gradient = ctx.createRadialGradient(mean.x, mean.y, circleRadius / 2, mean.x, mean.y, circleRadius * 2);
                gradient.addColorStop(0, 'rgba(255,255,255,255)');
                gradient.addColorStop(1, 'rgba(255,255,255,0)');
                ctx.fillStyle = gradient;
                ctx.fill(path);
                //ctx.stroke();
            }
        }
    }, [imgDimensions]);

    const SelectObject = (idx) => {
        setSelectedObjectIndex(idx);
        setSelectedObject(currentDataRange[idx]);
    }

    const getContainedSize = (img) => {
        var ratio = img.naturalWidth / img.naturalHeight
        var width = img.height * ratio
        var height = img.height
        if (width > img.width) {
            width = img.width
            height = img.width / ratio
        }
        return [width, height]
    }

    return (
        <Panel back={{ location: "./../../../" }} nopadding navigation={currentDataRange && currentDataRange.length > 1 ? {
            next: () => {
                if (selectedObjectIndex + 1 < currentDataRange.length) {
                    SelectObject(selectedObjectIndex + 1);
                }
            },
            previous: () => {
                if (selectedObjectIndex - 1 >= 0) {
                    SelectObject(selectedObjectIndex - 1);
                }
            }
        }
            : null
        } help="browser" title={"COLLECTED DATA - OBJECT VIEW"}>
            {
                selectedObject ?
                    <PanelsRow>
                        <WindowPanel width="75vw">
                            <Container>
                                <TransformWrapper ref={transformRef}
                                    centerOnInit
                                >
                                    <TransformComponent wrapperClass="phototransform">
                                        <ImageContainer>
                                            <Image ref={imgRef} src={
                                                `/data/locations/${params.locationId}/photos/${selectedObject.name}`
                                            } onLoad={x => {
                                                console.log(imgRef.current.clientWidth);
                                                setImgDimensions({ width: imgRef.current.width, height: imgRef.current.height })
                                                setTimeout(() => {
                                                    transformRef.current.resetTransform();
                                                }, 100);
                                            }}
                                            />
                                            <ColumnCanvas ref={canvasRef} width={imgDimensions.width} height={imgDimensions.height} left={0}>

                                            </ColumnCanvas>
                                        </ImageContainer>
                                    </TransformComponent>
                                    <ZoomControlsWrapper>
                                        <ZoomButton onClick={x => {
                                            transformRef.current.zoomIn();
                                        }}>
                                            <ButtonImageWrapper>
                                                <ZoomButtonDash />
                                                <ZoomButtonDash rotation="0deg" />
                                            </ButtonImageWrapper>
                                        </ZoomButton>
                                        <ZoomButton onClick={x => {
                                            transformRef.current.zoomOut();
                                        }}>
                                            <ButtonImageWrapper>
                                                <ZoomButtonDash />
                                            </ButtonImageWrapper>
                                        </ZoomButton>
                                    </ZoomControlsWrapper>
                                </TransformWrapper>
                            </Container>
                        </WindowPanel>
                        <WindowPanel>
                            <DescriptionContainer>
                                <PhotoDescription>
                                    <p>{locationData[params.locationId].name}, {locationData[params.locationId].city}, {locationData[params.locationId].country}</p>
                                    Filename: {selectedObject.name}
                                    <br />
                                    Taken on: {((selectedObject.date instanceof Date) ? selectedObject.date : new Date(selectedObject.date)).toLocaleString()}
                                    <br />
                                    Photo {selectedObjectIndex + 1} of {currentDataRange.length}
                                </PhotoDescription>
                            </DescriptionContainer>
                        </WindowPanel>
                    </PanelsRow>
                    : null
            }
        </Panel >
    );
}