import { useThree } from "@react-three/fiber";
import { useEffect, useState } from "react";
import { Vector3 } from "three";

import { OrbitControls } from "../Three/CustomOrbitControls";

export default function CameraController({ desiredZoom }) {
    const { camera, gl } = useThree();
    const [controls, setControls] = useState(null);

    useEffect(() => {
        let controls = new OrbitControls(camera, gl.domElement);
        controls.minDistance = 2;
        controls.maxDistance = 20;
        controls.enableZoom = false;
        setControls(controls);
        return () => controls.dispose();
    }, [camera, gl]);

    useEffect(() => {
        if (controls && camera) {
            controls.minDistance=controls.maxDistance=desiredZoom;
            controls.update();
        }
    }, [desiredZoom, controls]);

    return null;
}