import styled, { css } from "styled-components";

const WindowPanelStyle = styled.div`
    width: ${props => props.width ? props.width : "100%"};
    max-width:100%;
    /* max-height: 80vh; */
    /* height: 100%; */
    /* padding: 1em;
    ${props => !props.noborder && css`
        border: 2px solid black;
        border-radius: 25px;
    `}
    ${props => props.semiTransparent && css`
        background-color: rgba(61,61,61,0.5);`}
    ${props => props.gray && css`
        background-color: rgba(100,100,100, ${props.semiTransparent ? .5 : .67}); // #646464aa;
    `} */
`;

export default function WindowPanel({ children, gray, width = "50%", noborder, semiTransparent }) {

    return (
        <WindowPanelStyle gray={gray} width={width} noborder={noborder} semiTransparent={semiTransparent}>
            {children}
        </WindowPanelStyle>
    )
}