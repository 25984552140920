import { useFrame, useLoader } from "@react-three/fiber";
import { Suspense, useEffect, useRef, useState } from "react"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export default function LocationModel({ props, onObjectSelected }) {
    const ref = useRef();
    const { nodes, material } = useLoader(GLTFLoader, "/data/locations/0/models/court-of-lions-v4.glb");
    const [selectedObject, setSelectedObject] = useState(null);

    const selectedMaterial = new THREE.MeshStandardMaterial({ color: 0xff0000 });
    const defaultMaterial = new THREE.MeshStandardMaterial({ color: 0xaaaaaa });
    useFrame((state, delta) => {
        // ref.current.rotation.y += 0.01;
    });


    const handlePointer = (event, name, hover) => {
        if (!nodes)
            return;

        if (!name.includes("Column"))
            return;

        //set cursor to pointer
        document.getElementById("root").style.cursor = (hover) ? "pointer" : "default";

        nodes[name].material = (hover || selectedObject==name) ? selectedMaterial : defaultMaterial;
        event.stopPropagation();
    }

    const handleClick = (event, name) => {
        if (!nodes)
            return;

        if (!name.includes("Column"))
            return;

        setSelectedObject(name);
        onObjectSelected(name);
        event.stopPropagation();
    }

    return (
        (nodes) ?
            <group ref={ref} castShadow receiveShadow>
                {Object.keys(nodes).map(key => nodes[key]).map(node => {
                    node.material= node.name == selectedObject ? selectedMaterial : defaultMaterial;
                    node.castShadow=node.receiveShadow=true;
                    return (
                        <mesh
                            key={node.name}
                            position={node.position}
                            rotation={node.rotation}
                            scale={.1}
                            material={node.material}
                            onClick={(event) => handleClick(event, node.name)}
                            onPointerOver={(event) => { handlePointer(event, node.name, true) }}
                            onPointerOut={(event) => { handlePointer(event, node.name, false) }}
                        >
                            <primitive object={node} />
                            {/* <meshStandardMaterial
                                color={defaultMaterial.color}
                            /> */}
                        </mesh>
                    )
                })}
            </group>
            :
            null
    )
}