import { useNavigate } from "react-router";
import styled, { css } from "styled-components";

export const WindowWrapper = styled.div`
display: flex;
justify-content: center;
align-content: center;
padding-left: 20vw;
padding-right: 20vw;
padding-top: 17vh;
`;

export const PanelsRow = styled.div`
    display: flex;
    justify-content: space-between;
    max-height: 100%;
    height: 100%;
    /* max-height: 80vh; */
    gap: 50px;
`;

const WindowStyle = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 5px;
    color: #b1b1b1;
    font-weight:400;
    font-size: 18px;
    gap: 36px;
    /* padding: 28px 60px; */
    text-align: left;
    letter-spacing: .45px;
    line-height: 24px;
    height: 100%;
    `;

const WindowContent = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${props => props.solid ? "#383838" : "#383838AA"};
    ${props => !props.nopadding && css`
        padding: 18px 30px;
    `};

    letter-spacing: 2px;
    flex-grow: 1;
    overflow: auto;
`;

const Title = styled.h1`
    font-size: 1em;
    ${props => css`
        text-align: ${props.center ? "center" : "left"};
    `}
    color: white;
`;

const Header = styled.div`
    background-color: ${props => props.solid ? "#383838" : "#383838AA"};
    padding: 0px 16px;

    display: flex;
    flex-direction: row;
    letter-spacing: 5px;
    /* justify-content: space-between; */
    gap: 10px;
    align-items: center;
    ${props => props.center && css`
    justify-content: center;
    `}
`;

const HeaderButtonsRow = styled.div`
position: absolute;
top: -30px;
right: 15px;
display: flex;
flex-direction: row;
flex-wrap: nowrap;
`;

const HeaderButtonWrapper = styled.div`
    background-color: #262626;
    border-radius: 50px;
    padding: 10px;
    margin: 0px -5px;
`;
const HelpButton = styled.div`
  background-color: #bb86fc;
  color: black;
  font-size: 34px;
  padding: 8px;
  /* padding-top: 10px; */
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 30px;
  /* border: 1px solid transparent; */
  cursor: pointer;
`;

const Space = styled.div`
width: 50px;
`

export function Panel({ title, popup, help, back, navigation, solid, nopadding, offset, children }) {

    const navigate = useNavigate();

    return (
        <WindowStyle popup={popup} solid={solid} offset={offset}>
            {title ?
                <Header center={title?.center}>
                    <i className="fa-solid fa-bars"></i>
                    {
                        title ?
                            <Title center={title.center}>{title.text || title}</Title>
                            :
                            null
                    }
                    <HeaderButtonsRow>
                        {
                            navigation?.previous ?
                                <HeaderButtonWrapper>
                                    <HelpButton onClick={x => {
                                        navigation.previous();
                                    }}>
                                        <img src="/icons/icon_left.png" />
                                    </HelpButton>
                                </HeaderButtonWrapper>
                                : null
                        }
                        {
                            navigation?.next ?
                                <HeaderButtonWrapper>
                                    <HelpButton onClick={x => {
                                        navigation.next();
                                    }}>
                                        <img src="/icons/icon_right.png" />
                                    </HelpButton>
                                </HeaderButtonWrapper>
                                : null
                        }
                        {
                            navigation ? <Space /> : null
                        }
                        {
                            back ?
                                <HeaderButtonWrapper>
                                    <HelpButton onClick={x => {
                                        navigate(back.location || -1);
                                    }}>
                                        <img src="/icons/back.png" />
                                    </HelpButton>
                                </HeaderButtonWrapper>
                                : null
                        }
                        {
                            help ?
                                <HeaderButtonWrapper>
                                    <HelpButton onClick={x => {
                                        navigate("/help/" + help);
                                    }}>
                                        <img src="/icons/help.png" />
                                    </HelpButton>
                                </HeaderButtonWrapper>
                                : null
                        }
                        {
                            // closable ?
                            //     <div className="close" onClick={x => {
                            //         console.log(typeof closable);
                            //         if (typeof (closable) === 'string') {
                            //             console.log("navigating to " + closable);
                            //             navigate(closable);
                            //         }
                            //         else
                            //             navigate("../");
                            //     }}>&times;</div>
                            //     : null
                        }
                    </HeaderButtonsRow>
                </Header>
                :
                null
            }
            <WindowContent nopadding={nopadding}>
                {children}
            </WindowContent>
        </WindowStyle >
    )
}