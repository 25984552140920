import { useState } from "react";
import { Outlet, useMatch, useNavigate, useParams } from "react-router";
import { Description, DescriptionContainer, DescriptionContent } from "./LocationsScreen";
import ModelPreview from "../Components/ModelPreview";
import { Panel, PanelsRow } from "../Components/Panel";
import WindowPanel from "../Components/WindowPanel";
import styled from "styled-components";

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const Header = styled.p`
  font-weight: bold;
`;

export default function InvestigateScreen() {

    const params = useParams();
    const navigate = useNavigate();

    const popupOpened = useMatch("/locations/:id/:objectId/timeline/*");

    const [selectedObject, setSelectedObject] = useState(null);


    const ViewColumnData = (obj) => {
        let id = obj.match(/\d+/g);
        localStorage.clear();
        navigate(`${id}/timeline`);
    }

    return (
        <PanelsRow>
            <WindowPanel width="65vw">
                <ModelPreview onObjectSelected={x => {
                    setSelectedObject(x);
                    ViewColumnData(x);
                }} />
            </WindowPanel>
            <WindowPanel width="33vw">
                <Panel title={"SELECT OBJECT"} back={{location:"/locations"}} help="location">
                    <DescriptionContainer>
                        <Header>Alhambra - The Lions Court, Granada, Spain</Header>
                        <DescriptionContent>
                            <Description>
                                The rectangular courtyard measures about 28.7 meters long and 15.6 meters wide, with its long axis aligned roughly east-to-west.[11] The arches and columns of the surrounding portico are arranged in a complex pattern that is unique in the architecture of the Islamic world. Single columns alternate with groups of two or three columns to forming a visual rhythm that highlights certain parts of the façade. Each column or group of columns demarcates a bay: there are 17 bays on the north and south sides of the courtyard and 11 bays on the east and west sides. On the north and south sides, the central bay is wider than all the others as it leads to the entrance of the hall behind it. On the east and west sides, a pavilion structure projects into the courtyard from the portico.
                                {
                                    selectedObject ?
                                        <div>
                                            <h2>Column #{selectedObject} is selected</h2>
                                        </div>
                                        :
                                        null
                                }
                            </Description>
                            {
                                // selectedObject ?
                                //     <button className="btn" onClick={() => {
                                //         let id = selectedObject.match(/\d+/g);
                                //         localStorage.clear();
                                //         navigate(`${id}/timeline`);
                                //     }}>SHOW COLLECTED DATA</button>
                                //     : null
                            }
                        </DescriptionContent>
                    </DescriptionContainer>
                </Panel>
                {
                    popupOpened ?
                        <Overlay>
                            <Outlet />
                        </Overlay>
                        :
                        null
                }
            </WindowPanel>
        </PanelsRow>
    );
}