import { Canvas } from "@react-three/fiber";
import { EffectComposer, SSAO } from "@react-three/postprocessing";
import { Suspense, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CameraController from "./CameraController";
import LocationModel from "./LocationModel";

const Container = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    pointer-events: none;
`;

const ControlsInfo = styled.h3`
    position: absolute;
    text-shadow: 1px 1px 1px black;
    bottom: 0;
    left: 20px;
    font-size: 14px;
    text-align: start;
`;

const ZoomControlsWrapper = styled.div`
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    flex-direction: column;
    background: grey;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    height: 100px;
    width: 45px;
`;

const ZoomButton = styled.button`
    pointer-events: all;
    /* width: 80px; */
    height: 80px;
    margin: 1px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    color: white;
    background: #FFFFFF;
`;

const ZoomButtonDash = styled.div`
    position: absolute;
    /* left: 46%;
    right: 46%;
    top: 35%;
    bottom: 35%; */
    top: 50%;
    left: 50%;
    width: 5px;
    height: 20px;

    background: black;
    transform:translate(-50%,-50%)  rotate( ${props => props.rotation ? props.rotation : "-90deg"});

`;

const ButtonImageWrapper = styled.div`
    font-size: 3em;
    position: relative;
    width: 100%;
    height: 100%;
`;

export {ZoomButton, ZoomButtonDash, ZoomControlsWrapper, ButtonImageWrapper}

export default function ModelPreview({ onObjectSelected }) {

    const minZoom = 3;
    const maxZoom = 20;
    const [zoom, setZoom] = useState(5);
    const containerRef = useRef();

    useEffect(() => {
        if (containerRef && containerRef.current) {
            containerRef.current.addEventListener("wheel", scrollEventHandler);
            console.log("adding event listener");
            return () => {
                console.log("removing event listener");
                containerRef.current.removeEventListener("wheel", scrollEventHandler);
            }
        }
    }, [containerRef]);

    const scrollEventHandler = (e) => {
        e.preventDefault();
        let zoomDelta = e.deltaY / 100;
        changeZoom(zoomDelta);
    };

    const changeZoom = (value) => {
        setZoom((z) => {
            if (z + value > maxZoom || z + value < minZoom)
                return z;

            return z + value;
        });
    }

    return (
        <Container ref={containerRef}>
            <Canvas shadows linear>
                <CameraController desiredZoom={zoom} />
                <ambientLight intensity={1} />
                <directionalLight position={[10, 10, 0]}
                    castShadow
                    shadow-mapSize-width={1024}
                    shadow-mapSize-height={1024}
                    shadow-camera-far={50}
                    shadow-camera-left={-10}
                    shadow-camera-right={10}
                    shadow-camera-top={10}
                    shadow-camera-bottom={-10} />
                <spotLight position={[-10, 10, 0]} intensity={0.5} penumbra={1} />
                <Suspense fallback={null}>
                    <LocationModel onObjectSelected={x => onObjectSelected(x)} />
                </Suspense>
                <EffectComposer>
                    <SSAO
                        samples={30} // amount of samples per pixel (shouldn't be a multiple of the ring count)
                        rings={4} // amount of rings in the occlusion sampling pattern
                        distanceThreshold={1.0} // global distance threshold at which the occlusion effect starts to fade out. min: 0, max: 1
                        distanceFalloff={0.0} // distance falloff. min: 0, max: 1
                        rangeThreshold={0.5} // local occlusion range threshold at which the occlusion starts to fade out. min: 0, max: 1
                        rangeFalloff={0.1} // occlusion range falloff. min: 0, max: 1
                        luminanceInfluence={0.9} // how much the luminance of the scene influences the ambient occlusion
                        radius={20} // occlusion sampling radius
                        scale={0.5} // scale of the ambient occlusion
                        bias={0.01} // occlusion bias
                    />
                </EffectComposer>
            </Canvas>
            <Overlay>
                <ZoomControlsWrapper>
                    <ZoomButton onClick={x => changeZoom(-1)}>
                        <ButtonImageWrapper>
                            {/* <i className="fa-solid fa-magnifying-glass-plus" /> */}
                            <ZoomButtonDash />
                            <ZoomButtonDash rotation="0deg" />
                        </ButtonImageWrapper>
                    </ZoomButton>
                    <ZoomButton onClick={x => changeZoom(+1)}>
                        <ButtonImageWrapper>
                            {/* <i className="fa-solid fa-magnifying-glass-minus"/> */}
                            <ZoomButtonDash />
                        </ButtonImageWrapper>
                    </ZoomButton>
                </ZoomControlsWrapper>
                <ControlsInfo>Hold Left Mouse Button to rotate and Right Mouse Button to pan<br />Use Scroll Wheel to zoom</ControlsInfo>
            </Overlay>
        </Container>
    );
}