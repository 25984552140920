import 'core-js/actual/structured-clone';
let data = null;
let locationData = null;
let helpData = null;

const getData = async (locationId) => {
    if (!data) {
        try {
        let result = await fetch(`/data/locations/${locationId}/data.json`);
        console.log(result);
        data = await result.json();
        } catch (e) {
            console.log(e);
            return null;
        }
    }
    let clone = structuredClone(data);
    return clone;
};

const getLocationData = async () => {
    if (!locationData) {
        try {
        let result = await fetch(`/data/locations/locations.json`);
        locationData = await result.json();
        } catch (e) {
            console.log(e);
            return null;
        }
    }
    let clone = structuredClone(locationData);
    return clone;
}

const getHelpData = async () => {
    if (!helpData) {
        try {
        let result = await fetch(`/data/help.json`);
        helpData = await result.json();
        } catch (e) {
            console.log(e);
            return null;
        }
    }
    let clone = structuredClone(helpData);
    return clone;
}

export {getData, getLocationData, getHelpData};