import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import styled from 'styled-components';
import { getLocationData } from "../../Misc/data";

const Wrapper = styled.div`
    margin-left: 15%;
    height: 50%;
    overflow: auto;
    display: flex;
    flex-direction: row;
`;

const Image = styled.img`
width: 50%;
height: 90%;
object-fit: contain;
object-position: left top;
`

const ImageWrapper = styled.div`
    width: 50%;
    min-width: 50%;
    position: relative;
`

const HeaderText = styled.p`
    font-weight: bold;
    margin: 0px;
    margin-bottom: 20px;
`

const InfoText = styled.p`
    font-size: 14px;
    margin: 0px;
`;

const MagnifyingGlass = styled.img`
    position:absolute;
    left:10px;
    top:10px;
    width:75px;
    cursor: pointer;
    &:hover  {
        filter: drop-shadow(1px 1px 0 black) 
    }
`;

const InfoWrapper = styled.div`
    text-align: start;
`;

export default function TimelinePreview({ data }) {

    const params = useParams();

    const [locationData, setLocationData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        console.log(data);
        getLocationData().then(x => {
            setLocationData(x);
        })
    }, [data])

    return (
        <Wrapper>
            <ImageWrapper>
                <Image src={
                    `/data/locations/${params.locationId}/photos/${data.items[0].name}`
                } />
                {
                    data.items.length > 0 ?
                        <MagnifyingGlass src="/icons/zoom.png" onClick={() => {
                            navigate(`./${data.type}/${data.year}/${data.month}`);
                        }} />
                        : null
                }
            </ImageWrapper>
            {locationData ?
                <InfoWrapper>
                    <HeaderText>{locationData[0].name}</HeaderText>
                    {/* <InfoText>Year {data.year}</InfoText>
                    <InfoText>Month {data.month}</InfoText> */}
                    <InfoText>Filename {data.items[0].name}</InfoText>
                    <InfoText>Taken on {new Date(data.items[0].date).toLocaleString("dd")}</InfoText>
                    {
                        data.items.length > 1 ?
                            <InfoText><br />This is a data collection of {data.items.length} items, click on the magnifying glass to see all items</InfoText>
                            : null
                    }
                </InfoWrapper>
                :
                null
            }
        </Wrapper>
    );
}