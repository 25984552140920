import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Panel } from "../Components/Panel";
import WindowPanel from "../Components/WindowPanel";
import { getHelpData } from "../Misc/data";

export default function NeedHelpScreen({ helpCategory }) {
    const navigate = useNavigate();
    const params = useParams();
    const [helpData, setHelpData] = useState(null);

    useEffect(() => {
        getHelpData().then(x => {
            if (!helpCategory)
                helpCategory = params.contentTitle;

            setHelpData(x.find(y => y.category == helpCategory));
        })
    }, [])

    return (
        <Panel title="Help" back>
            <WindowPanel noborder width="100%">
                {
                    (helpData) ?
                        <div>
                            <h1>Help: {helpData.title}</h1>
                            <p dangerouslySetInnerHTML={{__html: helpData.text}}>
                            </p>
                        </div>
                        : null
                }
                {/* <button className="btn" onClick={() => {
                    navigate(-1);
                    //navigate(backUrl);
                }}>Back</button> */}
            </WindowPanel>
        </Panel>
    );
}