import { Panel } from "./Panel";
import WindowPanel from "./WindowPanel";
import styled, { css } from 'styled-components';
import React, { useEffect, useRef, useState } from "react";
import { getData } from "../Misc/data";
import { Navigate, Outlet, useNavigate, useParams } from "react-router";
import TimelineTitle from "./Timeline/TimelineTitle";
import TimelinePreview from "./Timeline/TimelinePreview";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0px 20px;
    margin-bottom: 20px;
    /* height: 50px; */
`

const YearText = styled.h2`
    ${props => props.white && css`
    color: white;
    `
    }
`

const TimelineLineContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
`;

const TimelineDateRow = styled.div`
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
    font-size: 10px;
`

const TimelineLine = styled.div`
    height: 5px;
    width: 97%;
    background-color: #5d4977;
    position: relative;
`;

const TimelineYearSeparator = styled.div`
    width: 5px;
    height: 100%;
    background-color: #8f6abe;
    position: absolute;
    left: ${props => `${props.spacing}%`};
`;

const SlidingWindow = styled.div.attrs(props => ({
    style: {
        left: `${props.x}px`,
        width: `${props.width}px`
    },
}))`
    position: absolute;
    height: 275%;
    top: -90%;
    background-color: #868686bb;
    border-radius: 50px;
`;

const TimelineMarker = styled.div.attrs(props => ({
    style: {
        left: `${props.x}px`,
    },
}))`
    position: absolute;
    top: -7px;
    width: 20px;
    height:20px;
    border-radius: 50px;
    background-color: #a077d6;
    cursor: pointer;
    ${props => props.main && css`
        /* width: 10px;
        top: -130%;
        height: 300%; */
    `}
`;

const MainTimelineMarker = styled.div.attrs(props => ({
    style: {
        left: `${props.offset}%`,
        width: `${props.width}px`
    },
}))`
    position: absolute;
    width: 20px;
    height:100%;
    background-color: #b28bf5;
`;

export default function Timeline() {

    const params = useParams();
    const navigate = useNavigate();
    const timelineLineRef = useRef();
    const photosTimelineLineRef = useRef();
    const thermalTimelineLineRef = useRef();
    const threeDTimelineRef = useRef();
    const slidingWindowRef = useRef();
    const [slidingWindowX, setSlidingWindowX] = useState(localStorage.getItem("slidingX") ?? 0);
    const [slidingWindowWidth, setSlidingWindowWidth] = useState(150);
    const [isMovingSlidingWindow, setIsMovingSlidingWindow] = useState(false);
    const timeSettings = {
        startDate: new Date(2014, 0, 1),
        endDate: new Date(2022, 0, 1),
        range: 1
    };
    const [currentYearRange, setCurrentYearRange] = useState(
        {
            minYear: timeSettings.startDate.getFullYear(),
            minMonth: timeSettings.startDate.getMonth(),
            maxYear: timeSettings.startDate.getFullYear() + 1,
            maxMonth: timeSettings.startDate.getMonth()
        });
    const [hoveredTimeMarker, setHoveredTimeMarker] = useState(null);
    const [previewedMarker, setPreviewedMarker] = useState(null);
    const [data, setData] = useState(null);


    useEffect(() => {
        getData(0).then(d => {
            let photosWithObject = d.filter(x => x.items.find(y => y.regions.find(z => z.columnId == params.objectId)));
            photosWithObject.forEach(x => {
                x.items = x.items.filter(y => y.regions.find(z => z.columnId == params.objectId));
            })
            // console.log(photosWithObject);
            // console.log(d);
            setData(photosWithObject);
            let cookieMarker = localStorage.getItem("previewedMarker");
            if (cookieMarker) {
                cookieMarker = JSON.parse(cookieMarker);
                let obj = photosWithObject.find(x => x.year == cookieMarker.year && x.month == cookieMarker.month && x.type == cookieMarker.type);
                console.log(obj);
                setPreviewedMarker(obj);
            }

            if (localStorage.getItem("timeRange"))
                setCurrentYearRange(JSON.parse(localStorage.getItem("timeRange")));

        });
    }, [])

    useEffect(() => {
        if (photosTimelineLineRef) {
            let timeWindow = (timeSettings.endDate.getFullYear() - timeSettings.startDate.getFullYear()) / timeSettings.range;
            let slidingWindowWidth = photosTimelineLineRef.current.getBoundingClientRect().width / timeWindow;
            setSlidingWindowWidth(slidingWindowWidth);
        }

    }, [photosTimelineLineRef, thermalTimelineLineRef, threeDTimelineRef, timeSettings.range, timeSettings.endDate, timeSettings.startDate]);

    useEffect(() => {
        let slidingWindowWidth = slidingWindowRef.current.getBoundingClientRect().width;
        let normalizedSlidingWindowX = slidingWindowX / timelineLineRef.current.getBoundingClientRect().width;
        let normalizedSlidingWindowSize = slidingWindowWidth / timelineLineRef.current.getBoundingClientRect().width;
        let normalizedSlidingWindowStart = normalizedSlidingWindowX;
        let normalizedSlidingWindowEnd = normalizedSlidingWindowX + normalizedSlidingWindowSize;

        let yearDiff = (timeSettings.endDate.getFullYear() - timeSettings.startDate.getFullYear());
        let startYear = timeSettings.startDate.getFullYear();

        let minYear = Math.floor(yearDiff * (normalizedSlidingWindowStart) + startYear);
        let maxYear = Math.floor(yearDiff * (normalizedSlidingWindowEnd) + startYear);

        let yearPointStep = timelineLineRef.current.getBoundingClientRect().width / yearDiff;

        let minYearStartX = (minYear - startYear) * yearPointStep;
        let maxYearStartX = ((maxYear + 1) - startYear) * yearPointStep;
        let minYearMonth = Math.floor(((slidingWindowX - minYearStartX) / yearPointStep) * 12);
        let maxYearMonth = 11 - Math.floor(((maxYearStartX - (slidingWindowX + slidingWindowWidth)) / yearPointStep) * 12); //11 because months are 0-11 
        if (maxYearMonth < 0)
            maxYearMonth = minYearMonth;

        console.log(`Year point step ${yearPointStep} minyearmonth ${minYearMonth} max month ${maxYearMonth}`);

        let yearRange = {
            minYear: minYear,
            minMonth: minYearMonth,
            maxYear: maxYear,
            maxMonth: maxYearMonth,
        };
        setCurrentYearRange(yearRange);
        localStorage.setItem("timeRange", JSON.stringify(yearRange));

    }, [slidingWindowX]);

    const drawTimelineMarkers = (targetTimelineRef, timeRange = null, type, onClick) => {
        if (!targetTimelineRef.current || !data)
            return;

        let minYear = timeRange ? timeRange.minYear : timeSettings.startDate.getFullYear();
        let minMonth = timeRange ? timeRange.minMonth : timeSettings.startDate.getMonth();
        let maxYear = timeRange ? timeRange.maxYear : timeSettings.endDate.getFullYear();
        let maxMonth = timeRange ? timeRange.maxMonth : timeSettings.endDate.getMonth();

        // console.log(data);
        //let filteredData = timeRange ? data.filter(item => new Date(item.date).getFullYear() >= minDate && new Date(item.date).getFullYear() <= maxDate && item.type === type) : data;
        let filteredData = timeRange ? data.filter(item =>
            (
                (item.year == minYear &&
                    item.month >= minMonth)
                ||
                (item.year == maxYear &&
                    item.month <= maxMonth)
            )
            &&
            item.type === type)
            : data;

        //  console.log(filteredData);
        let markers = filteredData.map(x => {
            //x.date = new Date(x.date);
            x.date = new Date(x.year, x.month);
            let jsMonth = parseInt(x.month);
            console.log(`Month ${jsMonth}`);
            let monthSum = 12;
            let monthsToEndOfYear = 12 - minMonth;
            let curMonth = (x.year == minYear) ? jsMonth - (minMonth) : jsMonth + monthsToEndOfYear;
            let normalizedMonth = curMonth / monthSum;
            let normalizedX = normalizedMonth * (targetTimelineRef.current.getBoundingClientRect().width - 20);
            console.log(`rendering ${x.year} ${x.month} Month sum ${monthSum} curMonth ${curMonth} normalized month ${normalizedMonth} norm x ${normalizedX}`);
            return <TimelineMarker key={x.id} x={normalizedX} main={targetTimelineRef.current == timelineLineRef.current}
                onClick={event => {
                    if (onClick) {
                        onClick(event, normalizedX, x);
                    }
                    {
                        setPreviewedMarker(x);
                        localStorage.setItem("slidingX", slidingWindowX);
                        let cookie = {
                            isCookie: true,
                            year: x.year,
                            month: x.month,
                            type: x.type,
                        };
                        localStorage.setItem("previewedMarker", JSON.stringify(cookie));
                        // navigate(`./${x.year}/${x.month}`);
                    }
                }
                }
            // onMouseOver={event => {
            //     let rect = event.target.getBoundingClientRect();
            //     if (targetTimelineRef.current != timelineLineRef.current) {
            //         setHoveredTimeMarker({
            //             data: x,
            //             x: rect.left,
            //             y: rect.top
            //         })
            //     }
            // }
            // }
            // onMouseOut={event => targetTimelineRef.current != timelineLineRef.current ? setHoveredTimeMarker(null) : null}
            />
        });
        return markers;
    }

    const moveSlidingWindowTo = (desiredX) => {
        let timelineRect = timelineLineRef.current.getBoundingClientRect();
        let slidingWindowRect = slidingWindowRef.current.getBoundingClientRect();

        if (desiredX - slidingWindowRect.width / 2 < timelineRect.left) {
            setSlidingWindowX(0);
        } else if (desiredX + slidingWindowRect.width / 2 > timelineRect.right) {
            setSlidingWindowX(timelineRect.width - slidingWindowRect.width);
        } else {
            setSlidingWindowX(desiredX - timelineRect.left - slidingWindowRect.width / 2);
        }
    }

    const drawTimelineYears = () => {
        let years = [];
        for (let i = timeSettings.startDate.getFullYear(); i <= timeSettings.endDate.getFullYear(); i++) {
            years.push(i);
        }
        return years.map(x =>
            <YearText white={x == currentYearRange.minYear || x == currentYearRange.maxYear}>{x}</YearText>
        );
    }

    const drawTimelineYearsMarkers = () => {
        let years = [];
        for (let i = timeSettings.startDate.getFullYear(); i <= timeSettings.endDate.getFullYear(); i++) {
            years.push(i);
        }
        return years.map((x, i) => {
            let ratio = (i / (years.length - 1)) * 100;
            return <TimelineYearSeparator spacing={ratio} />
        }
        );
    }

    const drawMainTimelineMarkers = (onClick) => {
        if (!timelineLineRef.current || !data)
            return;

        let yearGap = 100 / (timeSettings.endDate.getFullYear() - timeSettings.startDate.getFullYear()); //range 0-100% where each year starts
        let monthGap = yearGap / 12;
        let currentYearGap = 0;
        let maxGroupGap = 2; //if two data entries are X month apart from each other then create a new group for new entry, otherwise append to previous group
        let allGroups = [];
        for (let currentYear = timeSettings.startDate.getFullYear(); currentYear < timeSettings.endDate.getFullYear(); currentYear++) {
            let groups = []; //groups in particular year
            //for every month
            for (let m = 0; m < 12; m++) {
                let hasDataForThisMonth = data.some(item => item.year == currentYear && item.month == m);
                if (!hasDataForThisMonth)
                    continue;

                let hasToCreateNewGroup = true;
                if (groups.length > 0) {
                    let previousGroup = groups[groups.length - 1];
                    let endingMonth = previousGroup.startMonth + previousGroup.groupLength;
                    if (m - endingMonth < maxGroupGap) {
                        previousGroup.groupLength++;
                        hasToCreateNewGroup = false;
                    }
                    else {
                    }
                }

                if (hasToCreateNewGroup) {
                    groups.push({
                        baseOffset: currentYearGap,
                        startMonth: m,
                        groupLength: 1,
                    });
                }
            }
            groups.forEach(g => {
                allGroups.push(g);
            });

            currentYearGap += yearGap;
        }

        return allGroups.map(group => {
            return <MainTimelineMarker offset={group.baseOffset + (monthGap * group.startMonth)} width={(group.groupLength + 1) * 10}
            />
        })
    }

    return (
        <Panel title="COLLECTED DATA" back={{ location: "./../../" }} help="timeline">
            <WindowPanel width="100%">
                <Row>
                    <TimelineTitle title="TIMELINE" icon="/icons/timeline.png" main />
                    <TimelineLineContainer>
                        <TimelineDateRow>
                            {
                                drawTimelineYears()
                            }
                        </TimelineDateRow>
                        <TimelineLine
                            ref={timelineLineRef}
                            onMouseDown={(e) => {
                                moveSlidingWindowTo(e.clientX);
                                e.preventDefault();
                            }}
                        >
                            {
                                drawTimelineYearsMarkers()
                            }
                            {
                                // {
                                // drawTimelineMarkers(timelineLineRef, null, "", (e, x) => {
                                //     setSlidingWindowX(x - slidingWindowRef.current.getBoundingClientRect().width / 2);
                                //     e.stopPropagation();
                                //     e.preventDefault();
                                // }
                                // )}
                                // drawMainTimelineMarkers((e, x) => {
                                //         setSlidingWindowX(x - slidingWindowRef.current.getBoundingClientRect().width / 2);
                                //         e.stopPropagation();
                                //         e.preventDefault();                                        
                                // })
                                drawMainTimelineMarkers()
                            }
                            <SlidingWindow
                                x={slidingWindowX}
                                width={slidingWindowWidth}
                                ref={slidingWindowRef}
                                onMouseDown={(e) => {
                                    setIsMovingSlidingWindow(true)
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                                onMouseUp={(e) => {
                                    setIsMovingSlidingWindow(false)
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                                onMouseOut={(e) => {
                                    setIsMovingSlidingWindow(false)
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                                onMouseMove={
                                    (e) => {
                                        if (!isMovingSlidingWindow) return;

                                        moveSlidingWindowTo(e.clientX);
                                        e.stopPropagation();
                                    }
                                }
                            />
                        </TimelineLine>
                    </TimelineLineContainer>
                </Row>
                <Row>
                    <TimelineTitle title="PHOTOS" icon="/icons/photos.png" />
                    <TimelineLineContainer>
                        <TimelineDateRow>
                            <YearText white>{currentYearRange.minYear}</YearText>
                            <YearText white>{currentYearRange.maxYear}</YearText>
                        </TimelineDateRow>
                        <TimelineLine color="lightblue" ref={photosTimelineLineRef}>
                            {data ? drawTimelineMarkers(photosTimelineLineRef, currentYearRange, "photo", null) : null}
                        </TimelineLine>
                    </TimelineLineContainer>
                </Row>
                <Row>
                    <TimelineTitle title="THERMALS" icon="/icons/thermals.png" />
                    <TimelineLineContainer>
                        <TimelineDateRow>
                            <YearText white>{currentYearRange.minYear}</YearText>
                            <YearText white>{currentYearRange.maxYear}</YearText>
                        </TimelineDateRow>
                        <TimelineLine color="red" ref={thermalTimelineLineRef}>
                            {data ? drawTimelineMarkers(thermalTimelineLineRef, currentYearRange, "thermal", null) : null}
                        </TimelineLine>
                    </TimelineLineContainer>
                </Row>
                <Row>
                    <TimelineTitle title="3d SCANS" icon="/icons/3d.png" />
                    <TimelineLineContainer>
                        <TimelineDateRow>
                            <YearText white>{currentYearRange.minYear}</YearText>
                            <YearText white>{currentYearRange.maxYear}</YearText>
                        </TimelineDateRow>
                        <TimelineLine color="palegreen" ref={threeDTimelineRef}>
                            {data ? drawTimelineMarkers(threeDTimelineRef, currentYearRange, "3d", null) : null}
                        </TimelineLine>
                    </TimelineLineContainer>
                </Row>
                {
                    previewedMarker ?
                        <TimelinePreview data={previewedMarker} />
                        :
                        null
                }
            </WindowPanel>
        </Panel >
    );
}