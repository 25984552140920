import { useEffect } from "react";
import { useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useNavigate } from "react-router";
import styled, { css } from "styled-components";
import { Panel, PanelsRow } from "../Components/Panel";
import WindowPanel from "../Components/WindowPanel";
import { getLocationData } from "../Misc/data";
import { Mockup } from "./WelcomeScreen";

const CustomMapContainer = styled(MapContainer)`
    height: 100%;
    width: 100%;
`;

const Description = styled.p`
    font-size: 18px;
    text-align: ${props => props.textAlign || "justify"};
    overflow: auto;
    height: 100%;
    padding-right: 20px;
    /* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #5d4977;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bb86fc;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #cba4fc;
}
`;

const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    
`;

const DescriptionContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: auto;
    align-items: flex-end;
`;

export { Description, DescriptionContainer, DescriptionContent };

export default function LocationsScreen() {

    const navigate = useNavigate();
    const [locations, setLocations] = useState(null);

    const [currentSelectedLocation, setCurrentSelectedLocation] = useState(null);

    useEffect(() => {

        getLocationData().then(x => {
            setLocations(x);
        });

    }, []);

    return (
        <div className="h-100">
            <PanelsRow>
                <WindowPanel width="65vw" id="panel">
                    <CustomMapContainer center={[51.505, -0.09]} zoom={3}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {
                            locations ?
                                locations.map(location => (
                                    <Marker position={[location.lat, location.lng]}>
                                        <Popup onOpen={() => {
                                            setCurrentSelectedLocation(location);
                                        }}>
                                            {location.name}
                                        </Popup>
                                    </Marker>
                                ))
                                : null
                        }
                    </CustomMapContainer>
                </WindowPanel>
                <WindowPanel width="33vw">
                    <Panel title="SELECT LOCATION" help="map">
                        <DescriptionContainer>
                            <p>{currentSelectedLocation ? `${currentSelectedLocation.name}, ${currentSelectedLocation.city}, ${currentSelectedLocation.country}` : "Select location on the map"}</p>
                            {
                                currentSelectedLocation ?
                                    <DescriptionContent>
                                        <Description>{currentSelectedLocation.description}</Description>
                                        <button className="btn nomargin-x" onClick={x => {
                                            navigate(`./${currentSelectedLocation.id}`);
                                        }}>SHOW COLLECTED DATA</button>
                                    </DescriptionContent>
                                    :
                                    null
                            }
                        </DescriptionContainer>
                    </Panel>
                </WindowPanel>

            </PanelsRow>
        </div>
    );
}